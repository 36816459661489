import $ from "jquery";

let carouselDesktop = $('.images-container');
let carouselMobile = $('.content-slick-product');

export  function carousel() {
  let domain = window.location.protocol + "//" + window.location.hostname;
  if(carouselDesktop.is(':visible')) {
    $('.js-product-thumbs').not('.slick-initialized').slick({
      prevArrow: "<div class=\"slick-arrow slick-prev hidden-md-up \"><button type=\"button\" class=\"btn btn-link \"><svg class=\"af-product-list\"><use xlink:href=\"" + domain + "/themes/dev-theme/assets/img/icon-user.svg#icon-chevron-up\"></use></svg></button></div><div class=\"slick-arrow slick-up hidden-sm-down \"><button type=\"button\" class=\"btn btn-link \"><svg class=\"af-product-list\"><use xlink:href=\"" + domain + "/themes/dev-theme/assets/img/icon-user.svg#icon-chevron-up\"></use></svg></button></div>",
      nextArrow: "<div class=\"slick-next slick-arrow hidden-md-up \"><button type=\"button\" class=\"btn btn-link \"><svg class=\"af-product-list\"><use xlink:href=\"" + domain + "/themes/dev-theme/assets/img/icon-user.svg#icon-chevron-down\"></use></svg></button></div><div class=\"slick-next slick-arrow slick-down hidden-sm-down \"><button type=\"button\" class=\"btn btn-link \"><svg class=\"af-product-list\"><use xlink:href=\"" + domain + "/themes/dev-theme/assets/img/icon-user.svg#icon-chevron-down\"></use></svg></button></div>",

      dots: false,
      speed: 300,
      vertical: false,
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 1,
      focusOnSelect: true,
      draggable: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            vertical: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            centerMode: true,
            dots: true,
            infinite: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            vertical: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            dots: false,
          }
        }
      ]
    });
  }
  
  if(carouselMobile.is(':visible')) {
    $('.js-slick-product').not('.slick-initialized').slick({
      prevArrow: "<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><i class=\"material-icons left\"></i></button>",
      nextArrow: "<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><i class=\"material-icons right\"></i></button>",
    });
  }
}