/***
 * PixelManager
 ***/

export class PixelManager  {

  constructor () {
    this._AddToCart = [];
    this.debug=false;
  }


   event(eventName,type,params){
    if(eventName==="addToCart")
        this.onAddToCart(type,params);

     if(eventName==="ViewCatalog")
       this.onViewCatalog(type,params);
     if(eventName==="InitiateCheckout")
       this.onInitiateCheckout(type,params);
     if(eventName==="Purchase")
       this.onOrderConfirmation(type,params);

   }
  onInitiateCheckout(type,params) {
    if (type === "facebook"  &&  PixelManagerConfig.FacebookEnabled) {
      var paramsCatalog = {
        content_name: params.name,
        content_ids: params.productId,
        content_type: 'product',
        num_items: params.cart_quantity
      };
      fbq('track', 'InitiateCheckout', paramsCatalog);
    }
    if (type==="ga4"  &&  PixelManagerConfig.PManagerEnabledGOOGLE) {

      // Preapre ItemsJS
      let orderItems=[];
      for (var productItem in params.items){
        let item=params.items[productItem];
        //item.price=item.price.replace(".",",");
        let checkoutItem={
            item_id: item.id,
            item_name: item.name,
            item_brand: item.brand,
            quantity: item.quantity,
            currency: PixelManagerConfig.currency,
            price: parseFloat(item.price),
        }
        orderItems.push(checkoutItem);

      }

      gtag('event', 'begin_checkout', {
        currency: PixelManagerConfig.currency,
        'debug_mode':PixelManagerConfig.PManagerDebugGOOGLE,
        items: orderItems,
     //   value: params.price
      });
    }


  }
  onOrderConfirmation(type,params){

    if (type==="facebook"  &&  PixelManagerConfig.FacebookEnabled) {
      var paramsCatalog = {
        value: params.order_amount,
        content_ids: params.productId,
        order_quantity: params.cart_quantity,
        currency: PixelManagerConfig.currency,
        content_type: 'product',
      };
      fbq('track', 'Purchase', paramsCatalog);
    }

    if (type==="ga4"  &&  PixelManagerConfig.PManagerEnabledGOOGLE) {

      // Preapre ItemsJS
      let orderItems=[];
      for (var productItem in params.items){
        let item=params.items[productItem];
        //item.price=item.price.replace(".",",");
        let checkoutItem={
          item_id: item.id,
          item_name: item.name,
          item_brand: item.brand,
          quantity: item.quantity,
          currency: PixelManagerConfig.currency,
          price: parseFloat(item.price),
        }
        orderItems.push(checkoutItem);

      }

      gtag('event', 'purchase', {
        currency: PixelManagerConfig.currency,
        'debug_mode':PixelManagerConfig.PManagerDebugGOOGLE,
        items: orderItems,
        transaction_id: params.transaction_id
        //   value: params.price
      });
    }

  }
    onViewCatalog(type,params){
    if (type==="facebook" &&  PixelManagerConfig.FacebookEnabled) {
      var paramsCatalog = {
        content_name: params.name,
        content_ids: params.productId,
        content_type: 'product',
      };
      fbq('track', 'ViewCategory', paramsCatalog);
    }
  }
   onAddToCart(type,params){

      if (type==="facebook"  &&  PixelManagerConfig.FacebookEnabled) {
        var addToCart = {
          content_name: params.name,
          content_ids: [params.id],
          content_type: 'product',
          value: params.price,
          currency: PixelManagerConfig.currency

        };
        fbq('track', 'AddToCart', addToCart);
      }
     if (type==="ga4"  &&  PixelManagerConfig.PManagerEnabledGOOGLE) {

       gtag('event', 'add_to_cart', {
         currency: PixelManagerConfig.currency,
         'debug_mode':PixelManagerConfig.PManagerDebugGOOGLE,
         items: [{
           item_id: params.id,
           item_name: params.name,
           item_brand: params.brand,
           //item_category: 'pants',
           price:  params.price,
           currency: PixelManagerConfig.currency,
           quantity: 1
         }],
         value: params.price
       });
     }

    }
    productAddToCart(id){

      if(Array.isArray(id)) {

        Object.keys(id).map(e => {

          if (typeof(id[e]) === "object" && id[e].name === "id_product") id = id[e].value;
        });
      }

      Object.keys(productInformation.productId).map(e=> {
        try{

          if(productInformation.productId[e].id===id) {
            this.event("addToCart", "facebook",productInformation.productId[e]);
            this.event("addToCart", "ga4",productInformation.productId[e]);
          }
        }catch(e){console.log({err:e})}
      });

    }
    viewCatalog(){
      this.event("ViewCatalog", "facebook",categoryInformation);

    }
    initiateCheckout(){
      this.event("InitiateCheckout", "facebook",checkoutInformation);
      this.event("InitiateCheckout", "ga4",checkoutInformation);

  }
  orderConfirmation() {

    this.event("Purchase", "facebook", orderInformation);
    this.event("Purchase", "ga4", orderInformation);
  }


  }



